import { localStore } from './storage'
import Axios from 'axios/dist/axios'

function init (shop) {
  const file = `/frontend/build/${shop}/svg.html`
  const hash = window['svgHash']
  let data
  const insertIT = () => {
    document.body.insertAdjacentHTML('afterbegin', data)
  }
  const insert = () => {
    if (document.body) insertIT()
    else document.addEventListener('DOMContentLoaded', insertIT)
  }

  if (localStore.get('inlineSVGrev') === hash) {
    data = localStore.get('inlineSVGdata')
    if (data) {
      insert()
      return true
    }
  }

  Axios({
    method: 'get',
    url: file,
    responseType: 'text'
  }).then(result => {
    data = result.data
    insert()
    localStore.set('inlineSVGdata', result.data)
    localStore.set('inlineSVGrev', hash)
  })
}

export default init
