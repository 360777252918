const SESSION = 'sessionStorage'
const LOCAL = 'localStorage'
const ERR_MESS = 'Local storage is not available'

const getIt = (storage, key, json = false) => {
  try {
    return json ? JSON.parse(window[storage].getItem(key)) : window[storage].getItem(key)
  } catch (error) {
    console.error(ERR_MESS, error)
    return false
  }
}

const setIt = (storage, key, value) => {
  try {
    window[storage].setItem(key, value)
    return true
  } catch (error) {
    console.error(ERR_MESS, error)
    return false
  }
}

const removeIt = (storage, key) => {
  try {
    window[storage].removeItem(key)
    return true
  } catch (error) {
    console.error(ERR_MESS, error)
    return false
  }
}

export const sessionStore = {
  set: (key, value) => {
    return setIt(SESSION, key, value)
  },
  get: (key) => {
    return getIt(SESSION, key)
  },
  getJSON: (key) => {
    return getIt(SESSION, key, true)
  },
  remove: (key) => {
    return removeIt(SESSION, key)
  }
}

export const localStore = {
  set: (key, value) => {
    return setIt(LOCAL, key, value)
  },
  get: (key) => {
    return getIt(LOCAL, key)
  },
  getJSON: (key) => {
    return getIt(LOCAL, key, true)
  },
  remove: (key) => {
    return removeIt(LOCAL, key)
  }
}
